<template>
    <div class="spinner-container" v-show="loaderVisible">
        <div class="loader">

        </div>
    </div>
</template>
<script>
export default {
    name: "InitLoader",
    props: ["initData"],
    computed: {
      loaderVisible() {
        if (this.initData.hasOwnProperty("loader")) {
          return this.initData.loader.visible;
        }
        return false;
      },
    },
};
</script>
<style scoped>
.spinner-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 9999;
    background-color: rgba(35, 35, 35, 0.3);
}
.loader {
    position: relative;
    width: 48px;
    height: 48px;
    background: var(--otp-main-orange);
    transform: rotateX(65deg) rotate(45deg);
    /*transform: perspective(200px) rotateX(65deg) rotate(45deg);*/
    color: #fff;
    animation: layers1 1s linear infinite alternate;
}

.loader:after {
    content: '';
    position: absolute;
    inset: 0;
    background: rgba(255, 255, 255, 0.7);
    animation: layerTr 1s linear infinite alternate;
}

@keyframes layers1 {
    0% {
        box-shadow: 0 0 0 0
    }
    90%, 100% {
        box-shadow: 20px 20px 0 -4px
    }
}

@keyframes layerTr {
    0% {
        transform: translate(0, 0) scale(1)
    }
    100% {
        transform: translate(-25px, -25px) scale(1)
    }
}
</style>