export class CookieAgree {
    constructor(){
        this.body = document.querySelector('body');
        this.modalTitleText = 'Süti beállítások';
        this.modalBodyText = '<p>Weboldalunk információkat tárolhat vagy gyűjthet be a böngésződből, amit az esetek többségében sütik segítségével végzünk. Az információk vonatkozhatnak Rád, mint felhasználóra, a preferenciáidra, az általad használt eszközre, vagy az oldal elvárt működésére is akár, de általában nem alkalmas a közvetlen azonosításodra, de képes személyre szabottabb internetes élményt nyújtani.</p><p>Eldöntheted, hogy a meghatározott típusú sütik használatát engedélyezed-e.</p>';
        this.saveAllBtnText = 'Összes engedélyezése';
        this.saveSettingsBtnText = 'Beállítások mentése';
        this.showSettingsBtnText = 'Beállítások';
        this.modal = ''
        this.sections = [{
            title:'Működéshez szükséges sütik',
            description:'Ezen sütik elengedhetetlenül szükségesek a weboldal működéséhez, és ezért nem kapcsolhatók ki. Általában csak olyan tevékenységekre válaszul kerülnek elhelyezésre, mint például az adatvédelmi beállítások megadása, bejelentkezés vagy űrlapok kitöltése. A böngésződben beállíthatod a sütik blokkolását vagy az azokra való figyelmeztetést, de ebben az esetben előfordulhat, hogy az oldal meghatározott részei nem fognak működni.',
            more:'További információk',
            details: '<strong>PHPSESSID</strong> A PHP nyelven alapuló alkalmazások által generált cookie. Ez egy általános célú azonosító, amelyet a felhasználói munkamenet változóinak karbantartására használnak. Ez általában véletlenszerűen generált szám, felhasználása a webhelyre jellemző, de jó példa a bejelentkezett állapot fenntartása a felhasználó számára az oldalak között, 30 perc a lejárata' +
                '<br><strong>OITHCookieAgree</strong> Ebben tároljuk, hogy elfogadtad-e ezt a nyilatkozatot, 14 nap a lejárata',
            required:true,
            checked:true,
            switchClass:'general-switch'
        },{
            title:'Statisztika készítéshez szükséges sütik',
            description:'Ezen sütik segítségével nézzük az oldalunkon történő látogatásokat és a forgalom forrásait, hogy mérjük és javítsuk az oldalunk teljesítményét. A sütik segítenek nekünk meghatározni a legnépszerűbb és legkevésbé népszerű oldalakat, és segítségükkel figyeljük a látogatók mozgását az oldalon. A sütik által gyűjtött információk aggregált jellegűek, ezért anonimek. Mi a Google Analytics-t használjuk erre a célra, <a href="https://developers.google.com/analytics/resources/concepts/gaConceptsCookies?hl=hu" target="_blank">bővebb információk</a>',
            more:'További információk',
            details: '<strong>_utmt</strong> 10 perc a lejárata' +
                '<br><strong>_gat</strong> 10 perc a lejárata' +
                '<br><strong>_ga</strong> 2 év a lejárata' +
                '<br><strong>__utma</strong> 2 év a lejárata' +
                '<br><strong>__utmz</strong> 6 hónap a lejárata' +
                '<br><strong>__utmb</strong> 30 perc a lejárata' +
                '<br><strong>_gid</strong> egyedi azonosító',
            required:false,
            checked:true,
            switchClass:'static-switch'
        },{
            title:'Marketinghez szükséges sütik',
            description:'Ezen sütiket hirdetés kiszolgáló partnereink helyezik el az oldalunkon keresztül. A sütiket a vállalatok az érdeklődési körödnek megfelelő profil felállítására és ahhoz illeszkedő hirdetések megjelenítésére használhatják más oldalakon. A működésük során egyedileg azonosítják a böngészőt és az eszközt. Mi a <a href="https://policies.google.com/technologies/types?hl=hu" target="_blank">Google</a>-t és a <a href="https://www.facebook.com/policies/cookies/" target="_blank">Facebook</a>-t használjuk erre a célra, bővebb leírást a szolgáltatók nevére kattintva érsz el.',
            more:'További információk',
            details: '<strong>APISID</strong> Google sütije' +
                '<br><strong>SSID</strong> Google sütije' +
                '<br><strong>SID</strong> Google sütije' +
                '<br><strong>SAPISID</strong> Google sütije' +
                '<br><strong>HSID</strong> Google sütije' +
                '<br><strong>datr</strong> Facebook sütije' +
                '<br><strong>fr</strong> Facebook sütije' +
                '<br><strong>lu</strong> Facebook sütije',
            required:false,
            checked:true,
            switchClass:'marketing-switch'
        }
        ];
      
        this.cookieName = this.getCookieName()
        this.createSettingBtn()
        this.generateHTML()
        this.checkCookieExist()
    }

    createSettingBtn(){
        let div = document.createElement('div')
        div.classList.add('cookie-setup')
        div.innerHTML = '<i class="fas fa-cog"></i>'
        this.listener(div,'click',this.modalToggle.bind(this))
        this.body.appendChild(div)
    }

    // statistic cookie
    statisticCookie(status){
        if(status===true){
            gtag("consent", "update", {
                "analytics_storage": "granted",
                "ad_storage": "granted",
            });
        }else{
            gtag('consent', 'default', {
                'ad_storage': 'denied',
                'analytics_storage': 'denied'
            });
        }
    }

    // marketing cookie
    marketingCookie(status){
        if(status===true) {
            fbq('consent', 'grant');
        }else{
            fbq('consent', 'revoke');
        }
    }

    // acceptCookie
    acceptCookie(e){
        let staticSwitch = document.getElementsByClassName('static-switch')[0];
        if(staticSwitch.checked || e.target.dataset.saveAll){
            this.statisticCookie(true);
        }else{
            this.statisticCookie(false);
        }
        let marketingSwitch = document.getElementsByClassName('marketing-switch')[0];
        if(marketingSwitch.checked || e.target.dataset.saveAll){
            this.marketingCookie(true);
        }else{
            this.marketingCookie(false);
        }
        this.removeBanner();
    }

    generateHTML(){
        let cookieHolder = document.createElement('div');
        cookieHolder.classList.add('ck-modal','d-none');
        cookieHolder.setAttribute('id', 'ckAgree');
        // Modal content
        let modalContent = document.createElement('div');
        modalContent.classList.add('modal-content');
        // Modal header
        let modalHeader = document.createElement('div');
        modalHeader.classList.add('modal-header');
        let modalTitle = document.createElement('h5');
        modalTitle.innerText = this.modalTitleText;
        modalHeader.appendChild(modalTitle);
        modalContent.appendChild(modalHeader);
        // Modal body
        let modalBody = document.createElement('div');
        modalBody.classList.add('modal-body');
        let bodyText = document.createElement('p');
        bodyText.innerHTML = this.modalBodyText;
        modalBody.appendChild(bodyText);

        let saveAllBtn = document.createElement('button');
        saveAllBtn.classList.add('btn', 'btn-primary', 'float-end');
        saveAllBtn.dataset.saveAll = true;
        saveAllBtn.innerText = this.saveAllBtnText;
        this.listener(saveAllBtn, 'click', this.acceptCookie.bind(this));
        modalBody.appendChild(saveAllBtn);

        let showSettingsBtn = document.createElement('button');
        showSettingsBtn.classList.add('btn', 'btn-primary-outline', 'float-end', 'me-2');
        showSettingsBtn.innerText = this.showSettingsBtnText;
        this.listener(showSettingsBtn,'click', this.displaySettings.bind(this))
        modalBody.appendChild(showSettingsBtn);

        modalContent.appendChild(modalBody);
        cookieHolder.appendChild(modalContent);
        // Modal footer
        let modalFooter = document.createElement('div');
        modalFooter.classList.add('modal-footer','d-none');
        //add sections 
        for(let i=0; i<this.sections.length; i++){
            let section = this.generateSection(this.sections[i]);
            modalFooter.appendChild(section);
        }
        let saveSettingsBtn = document.createElement('button');
        saveSettingsBtn.classList.add('btn', 'btn-secondary');
        saveSettingsBtn.innerText = this.saveSettingsBtnText;
        this.listener(saveSettingsBtn, 'click', this.acceptCookie.bind(this));
        modalFooter.appendChild(saveSettingsBtn)

        modalContent.appendChild(modalFooter)

        this.body.appendChild(cookieHolder)
        this.modal = document.getElementById('ckAgree')
    }

    generateSection(data){
        let section = document.createElement('div');
        section.classList.add('sub-section');
        // Title
        let titleHolder = document.createElement('div');
        titleHolder.classList.add('in-row');
        let title = document.createElement('h6');
        title.innerHTML = data.title;
        if(data.required){
            title.innerHTML += ' <span class="small">(kötelező)</span>'
        }
        titleHolder.appendChild(title);
        section.appendChild(titleHolder);
        // Switch
        let switchToggle = document.createElement('label');
        switchToggle.classList.add('switch');
        let checkbox = document.createElement('input');
        checkbox.setAttribute('type','checkbox');
        if(data.required){
            checkbox.disabled = true;
        }
        checkbox.checked = data.checked;
        checkbox.classList.add(data.switchClass);
        let span = document.createElement('span');
        span.classList.add('slider', 'round');
        
        switchToggle.appendChild(checkbox);
        switchToggle.appendChild(span);
        titleHolder.append(title);
        titleHolder.append(switchToggle);
        // Description
        let description = document.createElement('p');
        description.innerHTML = data.description;
        section.appendChild(description)
        // More information
        let more = document.createElement('a');
        more.classList.add('more');
        more.innerText = data.more;
        section.appendChild(more);
        this.listener(more, 'click', this.toggleListener);
        // Details
        let details = document.createElement('p');
        details.classList.add('details', 'd-none');
        details.innerHTML = data.details;
        
        section.appendChild(details);
        return section;
    }

    getCookieName(){
        let origin = window.location.origin;
        let name = 'OITHCkAgree';
        if (origin === 'file://' || origin.includes('test')) {
            name = 'test_' + name;
        }
        return name;
    }

    // check if the cookie exist
    checkCookieExist(){
        let cookieAgreeCookie = this.getCookie();
        if(cookieAgreeCookie == null){
            this.body.style.overflowY = 'hidden';
            this.modal.classList.remove('d-none');
        }
    }

    //Get cookie
    getCookie() {
        let value = "; " + document.cookie;
        let parts = value.split("; " + this.cookieName + "=");
        if (parts.length === 2) return parts.pop().split(";").shift();
        else return null;
    }

    /*  REMOVE COOKIE BANNER  */
    removeBanner(){
        this.setCookie(1, 14);
        let cookieBanner = document.getElementById('ckAgree');
        cookieBanner.classList.add('d-none');
        this.body.style.overflowY = 'auto';
    }

    modalToggle(){
        this.modal.classList.toggle('d-none');
    }

    // Create cookie
    setCookie(cvalue, exdays) {
        let d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = this.cookieName + '=' + cvalue + ';' + expires + ';domain=.'+window.location.hostname+';path=/';
    }

    toggleListener(e){
        let element = e.target.nextSibling;
        element.classList.toggle('d-none');
    }

    displaySettings(){
        let modalFooter = document.querySelector('.modal-footer')
        modalFooter.classList.toggle('d-none');
    }

    listener(blockName,actionName,callFunction) {
        if (blockName !== undefined) {
            blockName.addEventListener(actionName, callFunction);
        }
    }
}