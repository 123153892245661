<template>
</template>

<script>
export default {
    name: "Head",
    props: ["contents","language"],
    computed: {
        pageId() {
          if(this.$route.matched.length===0){
            return null
          }
          return this.$route.matched[0].props.default.pageId
        },
        content() {
          console.log(this.pageId)
          let pageId = this.pageId
          if (pageId === null) {
              return null
          }
          console.log(this.contents)
          return this.contents[this.pageId]
        },
        headTitle() {
          let content = this.content
          if (content === null) {
              return ''
          }

          return content.head.title
        },
        headKeywords() {
          let content = this.content
          if (content === null) {
              return ''
          }

          return content.head.keywords
        },
        headDescription() {
          let content = this.content
          if (content === null) {
              return ''
          }

          return content.head.description
        }
    },
    watch: {
        headTitle(newValue) {
            document.querySelector('head title').innerHTML = newValue
            document.querySelector('meta[property="og:title"]').setAttribute('content', newValue)
        },
        headKeywords(newValue) {
            document.querySelector('meta[name="keywords"]').content = newValue
        },
        headDescription(newValue) {
            document.querySelector('meta[name="description"]').content = newValue
            document.querySelector('meta[property="og:description"]').setAttribute('content', newValue)

        }
    }
}
</script>

<style scoped>
</style>